import "images";
// import "fonts";
import "stylesheets/application";

// These polyfills ensure that modern JS features
import "core-js/stable"
import "regenerator-runtime/runtime"

// 3rd libs
import "@fortawesome/fontawesome-free";
import "bootstrap";

// Modules
import "modules/moment";
import "modules/nested_form";
import "modules/jquery-plugins";
import "modules/google_analytics";
import "modules/sentry";
// import "modules/cable";

import "modules/richtext";
import "modules/theme";
import "modules/crop";
import "modules/site";

import "controllers"

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

require("trix");
require("@rails/actiontext");


// this needs to be after Rails.start()
// https://github.com/ifad/data-confirm-modal
require('data-confirm-modal');

dataConfirmModal.setDefaults({
  title: 'Please Confirm',
  commit: 'Continue',
  cancel: "Cancel",
  commitClass: 'btn-danger btn-xs',
  cancelClass: 'btn-light btn-xs',
  verifyClass: 'form-control',
  modalClass: 'confirm-modal'
});
