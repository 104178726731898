import { Controller } from "stimulus"
import slugify from "slugify"

export default class extends Controller {
  static targets = ["source", "result"]

  connect() {
    // console.log(`Hello, Stimulus! - ${this.sourceTarget.value}`);
    // console.log(`Hello, Result! - ${this.resultTarget.value}`);
  }

  autoPopulate(event) {
    if (!!this.sourceTarget.value) {
      this.resultTarget.value = slugify(this.sourceTarget.value, {
        replacement: '-',  // replace spaces with replacement character, defaults to `-`
        remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
        lower: true,      // convert to lower case, defaults to `false`
        strict: false     // strip special characters except replacement, defaults to `false`
      });
    }
  }
}