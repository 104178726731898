import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["show", "unhide"];

  password() {
    console.log(`${this.value.textContent}`);
    console.log(`${this.input.type}`);

    if (this.value.textContent === "Show Code") {
      this.value.textContent = "Hide Code";
      this.input.type = "text";
    } else {
      this.value.textContent = "Show Code";
      this.input.type = "password";
    }
  }

  get value() {
    return this.showTarget;
  }
  get input() {
    return this.unhideTarget;
  }
}