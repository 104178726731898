import { Controller } from "stimulus"

// Import UJS so we can access the Rails.ajax method
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    this.roomsData = JSON.parse(this.element.dataset.rooms);

    const roomChange = () => {
      const roomId = $("#room_reservation_room_id").val();
      const roomData = this.roomsData[roomId];

      const startTime = $("#room_reservation_starts_at").val()
      const startMoment = moment(startTime,'MM/DD/YYYY hh:mma');
      const endTime = $("#room_reservation_ends_at").val()
      const endMoment = moment(endTime,'MM/DD/YYYY hh:mma');

      console.log(startTime);
      console.log(moment(startTime, 'MM/DD/YYYY hh:mma').add(-1, 'minute'))
      console.log(endTime);

      $('.datepicker-custom').datetimepicker({
        minDate: moment().startOf('day'),
        format: 'MM/DD/YYYY hh:mma',
        sideBySide: true,
        stepping: 30,
        enabledHours: roomData[2],
        daysOfWeekDisabled: roomData[0],
        useCurrent: true
      });

      $("#room_reservation_starts_at").datetimepicker('date', startMoment);
      $("#room_reservation_ends_at").datetimepicker('minDate', startMoment);
      $("#room_reservation_ends_at").datetimepicker('date', endMoment);

      $(".datepicker-custom").on("change.datetimepicker", function (e) {
        $('button[data-action="togglePeriod"]').removeClass('disabled');
      });

      $('.datepicker-custom .input-group-append').on("click", function (e) {
        // :-(
        setTimeout(function(){ $('button[data-action="togglePeriod"]').removeClass('disabled'); }, 300);
      })

      $('.reservation-start-time-form-group').off("change.datetimepicker");
      $('.reservation-start-time-form-group').on("change.datetimepicker", function (e) {
        var newEndTime = e.date.add(30, 'minutes').format('MM/DD/YYYY hh:mma');

        $("#room_reservation_ends_at").datetimepicker('minDate', newEndTime);
        $("#room_reservation_ends_at").datetimepicker('date', newEndTime);
      });

      $('.reservation-end-time-form-group').off("change.datetimepicker");
      $('.reservation-end-time-form-group').on("change.datetimepicker", function (e) {
        const roomId = $("#room_reservation_room_id").val();
        const startTime = $("#room_reservation_starts_at").val().replace(' PM', 'pm').replace(' AM', 'am')
        const endTime = $("#room_reservation_ends_at").val().replace(' PM', 'pm').replace(' AM', 'am')

        Rails.ajax({
          type: 'get',
          url:  '/room_reservations/change_room.js?id=' + roomId + '&sd=' +  startTime + '&ed=' + endTime
        })
      });

      Rails.ajax({
        type: 'get',
        url:  '/room_reservations/change_room.js?id=' + roomId + '&sd=' + $("#room_reservation_starts_at").val() + '&ed=' + $("#room_reservation_ends_at").val()
      })
    }


    $("#room_reservation_room_id").on('select2:select', function () {
      roomChange();
    });

    roomChange();
  }
}
