import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.bindHtmlEditable();
  }

  bindHtmlEditable(){
    this.element.querySelectorAll('.member-score[contenteditable="true"]').forEach(item => {
      item.addEventListener("input", (e) => this.updateScore(e));
      item.addEventListener("keypress", (e) => this.sendData(e));
    });
  }

  updateScore(e){
    const score = +e.target.textContent;
    if(isNaN(score) || score > 4){
      e.target.textContent = '';
      return false;
    }
  }

  sendData(e){
    const score = +e.target.textContent;
    if (e.key === 'Enter') {
      e.preventDefault();
      const query = JSON.parse(e.target.dataset.query);
      $.ajax({
        type: "GET",
        dataType: "script",
        data: {query: query, score: score},
        url: '/summary-dashboard/update_score'
      });
    }
  }

}