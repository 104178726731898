document.addEventListener("turbolinks:load", () => {

  // message
  $(".alert").delay(4000).slideUp(200, function () {
    $(this).alert('close');
  });

  // selection
    
  $('.select2').select2({
    theme: 'bootstrap4',
    tags: true,
    tokenSeparators: [',', ' ']
  });
  $('.select2-teams').select2({
    minimumResultsForSearch: -1,
  });

  $('.filter-select').on('change', function (e) {
    window.location.href = `${$(this).data('filter-url')}?filter=${$(this).val()}`;
  });

  // datepicker
  $('.datepicker').datetimepicker({
    format: 'MM-DD-YYYY hh:mma',
    sideBySide: true,
    stepping: 15
  });

  $('.dateonly-picker').datetimepicker({
    format: 'MM-DD-YYYY'
  });

  // handle ajax loaded modal
  $('.ajax-modal').on('click', function (e) {
    e.preventDefault();
    const data = $(this).data();

    $.get(data.url, function (html) {
      const modal = $('#global-modal');
      modal.find('.modal-title').html(data.title);
      modal.find('.modal-body').html(html);
      modal.modal();
    });
  });

  $('.confirm-item').on('click', function(e){
    $.ajax({
      type: "PUT",
      dataType: 'json',
      url: $(this).data('url'),
      data: { item_id: $(this).data('id'), complete: $(this).is(':checked') }
    }).done((res)=>{
      if ($(this).data('reload')) {
        window.location.reload();
      }
    });
  });

  // handle ajax loaded modal
  $('#group-check-in-btn').on('click', function (e) {
    e.preventDefault();
    const data = $(this).data();
    const checked = $(".checkin_checkbox:checked");
    let ids = [];
    if(checked.length > 0) {
      checked.each((i, el) => {
        ids.push(el.value);
      });
    }
    console.log("id: ", ids);

    $.get(data.url, { ids: ids }).done(function (html) {
      const modal = $('#global-modal');
      modal.find('.modal-title').html(data.title);
      modal.find('.modal-body').html(html);
      modal.modal();
    });
  });

  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.2
  });

  if (window.innerWidth < 768) {
    $("#collapseFiltersBoard").removeClass("show");
  };

  jQuery.validator.addClassRules({
    pin_code: {
      digits: true,
      minlength: 4,
      maxlength: 4
    }
  });

  $("form").validate();

});

document.addEventListener('turbolinks:before-cache', function () {
  // removing the select2 from all selects
  $(".select2.select2-hidden-accessible").select2('destroy');
});