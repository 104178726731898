document.addEventListener("turbolinks:load", () => {
  $('#avatar-file-input').on('change', function (evt) {
    var file = evt.target.files[0];
    // 2621440 == 2.5M size
    if ((file && file.size) > 2621440) {
      alert('Our image file size limit is 2.5MB. Please upload an image smaller than our limit.');
      return;
    };

    var data = new FormData();
    data.append('user[avatar]', file);

    var cropper = $('#cropper');
    cropper.modal({ focus: true, show: true });

    var cropForm = $('#avatar-cropper-form');
    var path = cropForm.attr('action');
    $(document).ajaxStart(function () {
      $(".spinner").show();
    });

    $(document).ajaxStop(function () {
      $(".spinner").hide();
    });

    cropForm.append("<input type='hidden' name='user[crop_x]' id='avatar_crop_x'>");
    cropForm.append("<input type='hidden' name='user[crop_y]' id='avatar_crop_y'>");
    cropForm.append("<input type='hidden' name='user[crop_w]' id='avatar_crop_w'>");
    cropForm.append("<input type='hidden' name='user[crop_h]' id='avatar_crop_h'>");

    $("#avatar_cropbox").html("<img src='" + URL.createObjectURL(file) + "' />");
    $('.modal-dialog').addClass("modal-dialog-scrollable");

    init_papercrop(function () {
      cropper.on('hidden.bs.modal', function () {
        $('#avatar_cropbox img').data('Jcrop').destroy();
        $("#avatar_cropbox").html("");
      });
    });

    $('#finish_cropping').on('click', function (e) {
      e.preventDefault();

      var other_data = cropForm.serializeArray();
      $.each(other_data, function (key, input) {
        data.append(input.name, input.value);
      });

      $.ajax({
        url: path,
        type: 'PUT',
        data: data,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
      }).done(function (data) {
        window.location.reload();
      });

    })
  });

  function init_papercrop(callback) {
    update_crop = function (coords) {
      $("#avatar_crop_x").val(Math.round(coords.x));
      $("#avatar_crop_y").val(Math.round(coords.y));
      $("#avatar_crop_w").val(Math.round(coords.w));
      $("#avatar_crop_h").val(Math.round(coords.h));
    };

    $('#avatar_cropbox img').Jcrop({
      onChange: update_crop,
      onSelect: update_crop,
      setSelect: [0, 0, 200, 200],
      aspectRatio: 1,
      boxWidth: 450,
    }, function () {
      callback();
    });
  };
});