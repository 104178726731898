import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.bindSelectTwo();
  }

  bindSelectTwo(){
    //$('.select2-custom').select2();
  }

}