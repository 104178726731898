import "./vendor/jqueryjcrop.js";

import 'select2';
import "jquery-confirm";
import "jquery-validation";
import "jquery-validation/dist/additional-methods.js";

import "tempusdominus-bootstrap-4";
import { jarallax } from 'jarallax';
import toastr from 'toastr'

window.jarallax = jarallax;

jQuery.validator.setDefaults({
  errorElement: 'span',
  errorPlacement: function (error, element) {
    error.addClass('invalid-feedback');
    element.closest('.form-group').append(error);
  },
  highlight: function (element, errorClass, validClass) {
    $(element).addClass('is-invalid');
  },
  unhighlight: function (element, errorClass, validClass) {
    $(element).removeClass('is-invalid');
  }
});

toastr.options = {
  "preventDuplicates": true,
  "preventOpenDuplicates": true,
  "closeDuration": 300
};
window.toastr = toastr;

